import { PlusIcon, MinusIcon } from "@heroicons/react/20/solid";

const QuantityInput = ({ quantity, onClick }) => {
  return (
    <div className="flex flex-row justify-center items-center space-x-2 w-full h-full">
      <button
        onClick={() => onClick(false)}
        type="button"
        className="basis-1/3 w-full h-full p-2 hover:scale-105 active:scale-95 text-mainGray border border-lightGray flex items-center justify-center"
      >
        <MinusIcon className="w-5 h-5" aria-hidden="true" />
      </button>
      <span className="basis-1/3 w-full h-full p-2 text-mainGray border border-lightGray flex items-center justify-center">
        {quantity}
      </span>
      <button
        onClick={() => onClick(true)}
        type="button"
        className="basis-1/3 w-full h-full p-2 hover:scale-105 active:scale-95 text-mainGray border border-lightGray flex items-center justify-center"
      >
        <PlusIcon className="w-5 h-5" aria-hidden="true" />
      </button>
    </div>
  );
};
export default QuantityInput;
