import { useState, useEffect } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { SiOpensea, SiRarible } from "react-icons/si";
import { RiInstagramFill } from "react-icons/ri";
import { AiFillTwitterCircle } from "react-icons/ai";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import QuantityInput from "../components/quantityInput";
import contract from "../contracts/contractABI.json";
import {
  getAccount,
  watchAccount,
  prepareWriteContract,
  writeContract,
  readContract,
  watchNetwork,
} from "@wagmi/core";
import { ethers } from "ethers";

const Home = () => {
  const CONTRACT_ADDRESS = "0xEdDb480143c51f87A4fB3b6bf290FD231c63017c";

  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isValidNetwork, setIsValidNetwork] = useState(false);
  const [mintError, setMintError] = useState(null);
  const [totalSupply, setTotalSupply] = useState(0);
  const [balanceOf, setBalanceOf] = useState(0);
  const [imageURL, setimageURL] = useState(
    "https://ipfs.io/ipfs/bafybeibqve3evmkggvnb2chjmkcf2a3v6cdrbjjrnz5xw6mwyqkr7oblji/JPB_Trump_3.jpg"
  );

  const account = getAccount();
  watchAccount((account) => {
    setIsConnected(account.isConnected);
    setMintError(null);
  });

  watchNetwork((network) => {
    setIsValidNetwork(network && network.chain && network.chain.id === 1);
    setMintError(null);
  });

  useEffect(() => {
    const getContractData = async () => {
      setIsOpen(
        await readContract({
          address: CONTRACT_ADDRESS,
          abi: contract.result,
          functionName: "isMintOpen",
          listenToBlock: true,
          overrides: { from: account.address },
        })
      );
      setTotalSupply(
        (
          await readContract({
            address: CONTRACT_ADDRESS,
            abi: contract.result,
            functionName: "totalSupply",
          })
        ).toNumber()
      );
      setBalanceOf(
        (
          await readContract({
            address: CONTRACT_ADDRESS,
            abi: contract.result,
            functionName: "balanceOf",
            args: [account.address],
          })
        ).toNumber()
      );
      let imageURL = JSON.parse(
        window.atob(
          (
            await readContract({
              address: CONTRACT_ADDRESS,
              abi: contract.result,
              functionName: "tokenURI",
              args: [0],
            })
          ).split(",")[1]
        )
      ).image;
      imageURL = imageURL.replace("ipfs://", "https://ipfs.io/ipfs/");
      setimageURL(imageURL);
    };
    getContractData();
  }, [isConnected, isValidNetwork]);

  const updateQuantity = async (isIncreament) => {
    if (isIncreament) {
      setQuantity(quantity + 1);
    } else {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    }
  };

  const mint = async (voteGuilty) => {
    try {
      const config = await prepareWriteContract({
        address: CONTRACT_ADDRESS,
        abi: contract.result,
        functionName: "mint",
        args: [quantity, voteGuilty],
        overrides: {
          from: account.address,
          value:
            totalSupply < 10 && balanceOf == 0
              ? ethers.utils
                  .parseEther("0.01")
                  .mul(quantity)
                  .sub(ethers.utils.parseEther("0.01"))
              : ethers.utils.parseEther("0.01").mul(quantity),
        },
      });
      await writeContract(config);
      setMintError(null);
    } catch (error) {
      if (
        error.message.includes(
          "insufficient funds for intrinsic transaction cost"
        )
      ) {
        setMintError("You don't have enough ether to mint.");
      }
    }
  };

  return (
    <div className="mx-8 xl:mx-32 my-8 xl:my-36 flex flex-col justify-center space-y-8">
      <div className="flex flex-col items-center justify-center">
        <div className="bg-bgWhite flex flex-col xl:flex-row justify-center space-y-20 xl:space-y-0 xl:space-x-20 p-[35px]">
          <div className="flex flex-col space-y-8 basis-1/2">
            <div className="drop-shadow-lg">
              <img src={imageURL} alt="Jelly Pool Bear Trump Edition" />
            </div>

            <div className="flex flex-col space-y-4 w-full">
              <div className="text-mainGray text-xl font-semibold">
                OPEN EDITION
              </div>
              <div className="w-full border-b border-lightGray"></div>
              <div className="flex flex-row space-x-16">
                <div className="flex flex-col space-y-2">
                  <div className="text-lightGray text-xs xl:text-sm">PRICE</div>
                  <div className="text-mainGray text-lg font-semibold">
                    0.01 ETH
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-lightGray text-xs xl:text-sm">
                    MINTED
                  </div>
                  <div className="text-mainGray text-lg font-semibold">
                    {totalSupply}
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-lightGray text-xs xl:text-sm">
                    OPEN TILL
                  </div>
                  <div className="text-mainGray text-lg font-semibold">
                    October 20th
                  </div>
                </div>
              </div>
              <div className="w-full border-b border-lightGray" />
              <div className="flex flex-row items-center justify-evenly w-full">
                {isOpen ? (
                  isConnected ? (
                    isValidNetwork ? (
                      <div className="flex flex-col xl:flex-row items-center justify-center space-y-2 xl:space-y-0 xl:space-x-2 w-full h-full">
                        <div className="xl:basis-1/4 w-full h-full flex items-center justify-center">
                          <QuantityInput
                            quantity={quantity}
                            onClick={updateQuantity}
                          />
                        </div>
                        <div className="xl:basis-3/4 flex flex-row items-center justify-center space-x-2 w-full h-full">
                          <button
                            type="button"
                            className="xl:basis-1/2 hover:scale-105 active:scale-95 bg-red underline underline-offset-4 w-full h-full px-2 md:px-4 py-3 xl:text-lg font-bold text-white"
                            onClick={() => mint(true)}
                          >
                            GUILTY
                          </button>
                          <button
                            type="button"
                            className="xl:basis-1/2 hover:scale-105 active:scale-95 bg-blue underline underline-offset-4 w-full h-full px-2 md:px-4 py-3 xl:text-lg font-bold text-white"
                            onClick={() => mint(false)}
                          >
                            NOT GUILTY
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="text-2xl font-semibold text-error">
                        You are on the wrong network
                      </div>
                    )
                  ) : (
                    <div className="text-2xl font-semibold text-mainGray">
                      Please connect your wallet
                    </div>
                  )
                ) : (
                  <div className="text-2xl font-semibold text-mainGray">
                    Mint closed
                  </div>
                )}
              </div>
              {mintError && (
                <div className="text-2xl font-semibold text-error mx-auto">
                  {mintError}
                </div>
              )}
              <div className="flex flex-col justify-center items-center">
                <div className="w-full border-b border-lightGray" />
                <div className="h-5 border-l border-lightGray" />
                <div className="mx-auto text-lightGray text-xs xl:text-sm">
                  MINT
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center space-y-8 basis-1/2">
            <div className="w-full flex flex-row justify-end items-center">
              <ConnectButton
                label="Connect Wallet"
                showBalance={true}
                accountStatus={"address"}
              />
            </div>
            <div className="grid grid-cols-2 gap-3">
              <a
                target="_blank"
                href="ipfs://bafybeibqve3evmkggvnb2chjmkcf2a3v6cdrbjjrnz5xw6mwyqkr7oblji"
                className="flex flex-col justify-center"
              >
                <div className="border border-lightGray px-2 py-2 h-16">
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-xs xl:text-sm text-lightGray truncate">
                      IPFS
                    </div>
                    <BiLinkExternal className="text-lightGray w-3 md:w-fit h-fit" />
                  </div>
                  <div className="font-semibold text-lg text-mainGray truncate">
                    ipfs://bafybeibqve3evmkggvnb2chjmkcf2a3v6cdrbjjrnz5xw6mwyqkr7oblji
                  </div>
                </div>
              </a>
              <a
                target="_blank"
                href={`https://etherscan.io/address/${CONTRACT_ADDRESS}`}
                className="flex flex-col justify-center"
              >
                <div className="border border-lightGray px-2 py-2 h-16">
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-xs xl:text-sm text-lightGray truncate">
                      CONTRACT
                    </div>
                    <BiLinkExternal className="text-lightGray w-3 md:w-fit h-fit" />
                  </div>
                  <div className="font-semibold text-lg text-mainGray truncate">
                    {CONTRACT_ADDRESS}
                  </div>
                </div>
              </a>
              <div className="border border-lightGray px-2 py-2 h-16 flex flex-col justify-center">
                <div className="text-xs xl:text-sm text-lightGray truncate">
                  STANDARD
                </div>
                <div className="font-semibold text-lg text-mainGray truncate">
                  ERC721A
                </div>
              </div>
              <div className="border border-lightGray px-2 py-2 h-16 flex flex-col justify-center">
                <div className="text-xs xl:text-sm text-lightGray truncate">
                  BLOCKCHAIN
                </div>
                <div className="font-semibold text-lg text-mainGray truncate">
                  ETH
                </div>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row justify-center w-full">
              <div className="flex flex-row w-full xl:basis-1/2">
                <a
                  target="_blank"
                  href="https://opensea.io/assets?search%5Bquery%5D=jellypoolbear"
                  className="basis-1/4 border border-lightGray px-2 py-2 flex items-center justify-center h-16 text-mainGray font-semibold text-lg"
                >
                  <SiOpensea className="w-6 h-fit" />
                </a>
                <a
                  target="_blank"
                  href="https://rarible.com/manuelwstepan"
                  className="basis-1/4 border border-lightGray px-2 py-2 flex items-center justify-center h-16 text-mainGray font-semibold text-lg"
                >
                  <SiRarible className="w-6 h-fit" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/_artist_at_work/"
                  className="basis-1/4 border border-lightGray px-2 py-2 flex items-center justify-center h-16 text-mainGray font-semibold text-lg"
                >
                  <RiInstagramFill className="w-6 h-fit" />{" "}
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/manuelwstepan"
                  className="basis-1/4 border border-lightGray px-2 py-2 flex items-center justify-center h-16 text-mainGray font-semibold text-lg"
                >
                  <AiFillTwitterCircle className="w-6 h-fit" />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
